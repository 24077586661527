//1已立项2进行中3已完工4已验收5已中止 
const state = [{
    value: 1,
    label: '已立项',
    disabled: true
}, {
    value: 2,
    label: '进行中',
    disabled: false
}, {
    value: 3,
    label: '已完工',
    disabled: false
}, {
    value: 4,
    label: '已验收',
    disabled: false
}, {
    value: 5,
    label: '已中止',
    disabled: false
}]

export default state;