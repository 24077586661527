<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh">刷新</el-button>
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size:12px;"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <pl-table
      :data="data"
      border
      stripe
      :row-class-name="tableRowClassName"
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclickTable"
    >
      <el-table-column label="项目编号" prop="billCode" show-overflow-tooltip></el-table-column>
      <el-table-column label="项目名称" prop="projectName" show-overflow-tooltip></el-table-column>
      <el-table-column label="项目状态" prop="billStateName"></el-table-column>
      <el-table-column label="预算成本">
        <el-table-column label="物资材料" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.goodsBudget|thousands(2)}}</template>
        </el-table-column>
        <el-table-column label="费用开支" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.expenseBudget|thousands(2)}}</template>
        </el-table-column>
        <el-table-column label="人工成本" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.laborBudget|thousands(2)}}</template>
        </el-table-column>
        <el-table-column label="合计" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.totalBudget|thousands(2)}}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="实际消耗">
        <el-table-column label="物资材料" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.goodsAmount|thousands(2)}}</template>
        </el-table-column>
        <el-table-column label="费用开支" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.expenseAmount|thousands(2)}}</template>
        </el-table-column>
        <el-table-column label="人工成本" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.laborAmount|thousands(2)}}</template>
        </el-table-column>
        <el-table-column label="合计" show-overflow-tooltip>
          <template slot-scope="scope">{{scope.row.totalAmount|thousands(2)}}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="合同金额" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.contractAmount|thousands(2)}}</template>
      </el-table-column>
      <el-table-column label="回款金额" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.recAmount|thousands(2)}}</template>
      </el-table-column>
      <el-table-column label="毛利" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.gross|thousands(2)}}</template>
      </el-table-column>
    </pl-table>
  </div>
</template>

<script>
import ProjectSelect from "@/components/ProjectSelect.vue";
import tableHeight from "@/common/tableHeightMixins";
import billState from "@/views/project/BillState.js";
import toExcel from "@/common/toExcel";
export default {
  mixins: [tableHeight],
  components: {
    ProjectSelect
  },
  data() {
    return {
      filter: {},
      data: []
    };
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      let params = { ...this.filter };
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$get("ProjectSynthesize/Summary", params)
        .then(r => {
          r.forEach(item => {
            item.billStateName = this.getBillStateLabel(item.billState);
            item.totalBudget =
              item.goodsBudget + item.expenseBudget + item.laborBudget;
            item.totalAmount =
              item.goodsAmount + item.expenseAmount + item.laborAmount;
            item.gross = item.contractAmount - item.totalAmount;
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    //毛利负值为红色
    tableRowClassName({ row }) {
      if (row.gross < 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
    getBillStateLabel(value) {
      let res = "";
      let item = billState.find(r => r.value == value);
      if (item) {
        res = item.label;
      }
      return res;
    },
    dblclickTable(row) {
      this.$store.commit("setFilterCache", {
        name: "ProjectSynthesizeDetail",
        filter: {
          projectId: row.id
        }
      });
      this.$router.push({
        name: "ProjectSynthesizeDetail"
      });
    },
    excel() {
      let list = [];
      this.data.forEach(item => {
        list.push({
          billCode: item.billCode,
          projectName: item.projectName,
          billStateName: item.billStateName,
          goodsBudget: item.goodsBudget,
          expenseBudget: item.expenseBudget,
          laborBudget: item.laborBudget,
          totalBudget: this.amountFormat(item.totalBudget),
          goodsAmount: this.amountFormat(item.goodsAmount),
          expenseAmount: this.amountFormat(item.expenseAmount),
          laborAmount: this.amountFormat(item.laborAmount),
          totalAmount: this.amountFormat(item.totalAmount),
          contractAmount: item.contractAmount,
          recAmount: this.amountFormat(item.recAmount),
          gross: this.amountFormat(item.gross)
        });
      });
      let header = [
        [
          "项目编号",
          "项目名称",
          "项目状态",
          "预算成本",
          "",
          "",
          "",
          "实际消耗",
          "",
          "",
          "",
          "合同金额",
          "回款金额",
          "毛利"
        ],
        [
          "",
          "",
          "",
          "物资材料",
          "费用开支",
          "人工成本",
          "合计",
          "物资材料",
          "费用开支",
          "人工成本",
          "合计",
          "",
          "",
          ""
        ]
      ];
      let column = [
        "billCode",
        "projectName",
        "billStateName",
        "goodsBudget",
        "expenseBudget",
        "laborBudget",
        "totalBudget",
        "goodsAmount",
        "expenseAmount",
        "laborAmount",
        "totalAmount",
        "contractAmount",
        "recAmount",
        "gross"
      ];
      toExcel(header, column, list, "项目综合", [
        {
          s: {
            c: 0, //开始列
            r: 0 //开始行
          },
          e: {
            c: 0, //结束列
            r: 1 //结束行
          }
        },
        {
          s: {
            c: 1, //开始列
            r: 0 //开始行
          },
          e: {
            c: 1, //结束列
            r: 1 //结束行
          }
        },
        {
          s: {
            c: 2, //开始列
            r: 0 //开始行
          },
          e: {
            c: 2, //结束列
            r: 1 //结束行
          }
        },
        {
          s: {
            c: 11, //开始列
            r: 0 //开始行
          },
          e: {
            c: 11, //结束列
            r: 1 //结束行
          }
        },
        {
          s: {
            c: 12, //开始列
            r: 0 //开始行
          },
          e: {
            c: 12, //结束列
            r: 1 //结束行
          }
        },
        {
          s: {
            c: 13, //开始列
            r: 0 //开始行
          },
          e: {
            c: 13, //结束列
            r: 1 //结束行
          }
        },
        {
          s: {
            c: 3, //开始列
            r: 0 //开始行
          },
          e: {
            c: 6, //结束列
            r: 0 //结束行
          }
        },
        {
          s: {
            c: 7, //开始列
            r: 0 //开始行
          },
          e: {
            c: 10, //结束列
            r: 0 //结束行
          }
        }
      ]);
    }
  }
};
</script>

<style>
</style>